<template>
<div>
  <div v-if="!verifyRegistration">
    <topHeader :haveBanner="false" />
    <div class="authentication __authimg">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-3 col-md-2 col-sm-2"></div>
          <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8">
            <p class="authentication__caption text-center">
              Find and book top talents and mentors across Africa
            </p>
          </div>
          <div class="col-xl-4 col-lg-3 col-md-2 col-sm-2"></div>
        </div>

        <div class="row">
          <div class="col-xl-3 col-lg-2 col-md-1 col-sm-1"></div>
          <div class="col-xl-3 col-lg-4 col-md-5 col-sm-5">
            <router-link to="/company-register">
              <div class="authentication__startcard">
                <img
                  src="../assets/img/procomp.png"
                  class="authentication__startcard--authimg"
                />
                <p class="authentication__startcard--starp">
                  Register <span>as a</span> <span>Company</span>
                </p>
                <p class="authentication__startcard--starp1">
                  Start Now <img src="../assets/img/starrow1.png" />
                </p>
              </div>
            </router-link>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-5 col-sm-5">
            <router-link to="/professional-register">
              <div class="authentication__startcard">
                <img
                  src="../assets/img/authsupimg.png"
                  class="authentication__startcard--authimg"
                />
                <p class="authentication__startcard--starp">
                  Register <span> as a </span> <span> professional</span>
                </p>
                <p class="authentication__startcard--starp1">
                  Start Now <img src="../assets/img/starrow1.png" />
                </p>
              </div>
            </router-link>
          </div>
          <div class="col-xl-3 col-lg-2 col-md-1 col-sm-1"></div>
        </div>
      </div>
      <div class="__spacet7p"></div>
    </div>
    <topFooter />
  </div>

  <div style="width: 100vw; height: 100vh; display: flex; justifyContent: center; alignItems: center;" v-if="verifyRegistration">
    <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="80px" height="80px" viewBox="0 0 128 128" xml:space="preserve">
      <rect x="0" y="0" width="100%" height="100%" fill="#FFFFFF" />
      <g>
        <path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#2267d6" />
        <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite">
        </animateTransform>
      </g>
    </svg>
  </div>
</div>
</template>

<script>
import $ from "jquery";
import { mapActions } from "vuex";

import topHeader from "../components/topHeader.vue";
import Loader from "../components/loader";
import topFooter from "../components/topFooter";

export default {
  components: {
    topHeader,
    Loader,
    topFooter,
  },
  data() {
    return {
      verifyRegistration: false
    }
  },
  mounted() {
    $(document).ready(function () {
      $(".customer-logos").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1500,
        prevArrow: $(".cust-prev"),
        nextArrow: $(".cust-next"),
        pauseOnHover: true,
        centerMode: true,
        centerPadding: "130px",
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              centerMode: true,
              centerPadding: "90px",
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              centerMode: true,
              centerPadding: "150px",
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: "0px",
            },
          },
        ],
      });
    });

    this.verifyRegistration = this.$route.query.key ? true:false;
  },
  methods: mapActions(['verifyUser']),
  async created() {
    if (this.$route.query.key) {
      const result = await this.verifyUser(this.$route.query.key);

      if (result.errors && result.errors.length > 0) {
        return this.$toast.error(`${result.errors[0]}`);
      }

      this.$toast.success(`${result.message}`);
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="scss">
</style>
